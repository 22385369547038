import React from "react"

import Layout from "../components/layout"
import { white } from "ansi-colors";

const IndexPage = () => (
  <Layout>
    <div style={{ color: white, fontFamily: 'Source Serif Pro', textAlign: 'center', marginTop: '300px', fontSize: '60px' }}>
      {'{'} Tawny: Labs {'}'}
    </div>
  </Layout>
)

export default IndexPage
